import React from "react";
// import { useState } from 'react';
// import {Login} from "./pages/Login";

import Scanner from './Scanner';


// import { Nav } from 'react-bootstrap';

function App() {
   
  //  const [login, setLogin] = useState(false);

  //  if(!login){
  //    // console.log('Login ', login);
  //    return <Login entrar={setLogin}></Login>
  //  }


  return (
    <Scanner/>
  );
}

export default App;
