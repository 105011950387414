import { useEffect, useState } from "react";
import { DataWSServers } from "../models/dataWS";
import { ValoresInterfaceWS } from "../models/valores";


export const useWSLista = () => {

    const [values, setValues] = useState<ValoresInterfaceWS[]>([]);

    useEffect(() => {
       
        let url = `wss://escanner.carlokart.com/lista`;
        //  let url = 'ws://192.168.1.52:4000';
     
         // const ws = new Websocket(url);
         const ws = new WebSocket(url);
         
         ws.onopen = () => {
           console.log(`Conectado a WS PURO -> /lista`);
         }
         ws.onmessage = (ev) => {
           // console.log('Data desde server', ev.data);
           let data:DataWSServers = JSON.parse(ev.data.toString());
            // console.log('DAta desde server Lista',data);
           if(data.valoresSmall.length >= 1){
             //  console.log('Leng maximos', maximos.length)
            
             setValues(data.valoresSmall);
           }
     
         }

         ws.onerror = (ev) => {
             console.log(`Problema al conectar con la url ${url}`);
             console.log(`Error -> ${ev}`);
         }
          
          return () => {
            console.log('Desmontando el HOOK');
            ws.close();
          }
        }, []);

    return values;
}