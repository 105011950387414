
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// import {Candidatas} from "./pages/Candidatas";
// import {SmallCaps} from "./pages/SmallCaps";
// import {SmallCapsFMP} from "./pages/SmallCapsFMP";
import {Screnner} from "./pages/Screener";
// import {Filtros} from "./pages/Filtros";
// import {Alertas} from "./pages/Alertas";
import { Home } from './pages/Home';
// import {Estrategias} from "./pages/Estrategias";
import {NavbarCustom} from './components/Navbar';
import {Container} from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";



// import { Nav } from 'react-bootstrap';

function Scanner() {

  return (
    <>
    <div className="App">
      <Router>
      <Container fluid>
       <NavbarCustom/>
      
       <Routes>
          {/* <Route path="/filtros">
            <Filtros/>
          </Route> */}
          
          {/* <Route path="/alertas">
            <Alertas/>
          </Route> */}
          <Route path="/screener/small" element={<Screnner cap='small'/>}/>
            
         
          <Route path="/screener/mcaps" element={ <Screnner cap='medium'/>}/>
           
         
          <Route path="/screener/lcaps" element={ <Screnner cap='large'/>}/>
           
         
          <Route path="/" element={<Home/>}/>
          
        
        </Routes>
      </Container>

      </Router>
      
    </div>
    </>
  );
}

export default Scanner;
