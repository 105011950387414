
import {  ValoresInterfaceWS } from "./valores";


export interface Scanner {
    // Aquí iremos poniendo lo que necesitemos
    // para el estado de la pagina
    valores: ValoresInterfaceWS[],
    
    rompenMaximoWS: ValoresInterfaceWS[],
    gestion: any
    buscarNuevosValores: boolean,
    actualizarValores: boolean,
    
}


export const initialState: Scanner = {
    valores: [],
   
    rompenMaximoWS: [],
    gestion: undefined,
    buscarNuevosValores: false,
    actualizarValores: false
}