
import {Operacion} from '../components/Operacion';

import {TablaDinamicaWS} from '../components/TablaDinamicaWS/TablaDinamicaWS';
import {TablaMaximos} from '../components/TablaMaximos/TablaMaximos';
// import Split from 'react-split'
import { SplitPane } from "react-collapse-pane";

import {ScrennerProvider} from '../context/ScannerContext';



export const Screnner = (props:any) => {
   
  console.log('Props en Screener', props.cap)
  
    return (
      
     <ScrennerProvider>
      <Operacion/>
        <SplitPane split="vertical"
          className="overflow-auto"
          resizerOptions={{
           css: {
             width: '4px',
             background: 'rgba(0, 0, 0, 0.5)',
           }
           }}>
          <TablaMaximos/>
          <TablaDinamicaWS cap={props.cap}/>

        </SplitPane>
     </ScrennerProvider>
    );
};

