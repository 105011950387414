import{ Navbar, Nav, Form, FormControl, Button} from "react-bootstrap";



export const NavbarCustom = () => {
    return (
        <>
  <Navbar expand="lg" bg="dark" variant="dark">
    <Navbar.Brand href="/">Scaner Stocks!</Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link href="/">Escaner</Nav.Link>
      <Nav.Link href="/screener/small">Radar Small caps</Nav.Link>
      <Nav.Link href="/screener/mcaps">Radar Medium caps</Nav.Link>
      <Nav.Link href="/screener/lcaps">Radar Large caps</Nav.Link>
      <Nav.Link href="/estrategias">Estrategias</Nav.Link>
      <Nav.Link href="/filtros">Filtros Acciones</Nav.Link>
      <Nav.Link href="/alertas">Alertas</Nav.Link>
    </Nav>
    <Form inline>
      <FormControl type="text" placeholder="Buscar Accion" className="mr-sm-2" />
      <Button variant="outline-info">Buscar</Button>
    </Form>
    </Navbar.Collapse>
  </Navbar>

</>
    );
}