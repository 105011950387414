import {  useState} from 'react';


import {Container, Row, Col, Table} from 'react-bootstrap';

import useSound from 'use-sound';
 
 /* @ts-ignore */
import alarm from "../../sounds/notificacion1.mp3";
import { useWSMaximos} from '../../hooks/useWSMaximos';



export const TablaMaximos = () => {
 
  
   const [repro, setRepro] = useState(false);
   const [play] = useSound(alarm);
  
   
   const maximos = useWSMaximos();
  

   function handleSound(e:any){
    if(!repro){
      console.log('Sonido ON!')
       setRepro(true);
    } else {
      console.log('Sonido OFF!')
      setRepro(false);
    }

  }

   
  return(
    <>
      {
        // TODO: Ojo con esto a ver si así funciona
        repro ? play() : null
      }
        <Container>
            <Row>
              <Col>

            <h4>Valores que rompen el Maximo</h4>
            <button onClick={handleSound}>Habiliar sonido</button>
             <p>Sonido {repro ? 'Activado': 'Desactivado'}</p>
           
            <Table bordered>
               <thead>
                 <tr>
                  <th>Hora</th>
                   <th>Ticker</th>
                   <th>Precio</th>
                   <th>Volumen</th>
                   <th>Subida</th>
                   <th>Acciones</th>
                 </tr>
               </thead>
               <tbody>
                 {
                   maximos ?
                   
                  maximos.map( (valor, index) => {    
                      return( 
                        <tr key={index}>
                          <td>{valor.updated}</td>
                          <td>{valor.ticker}</td>
                          <td>{valor.cotizacion}</td>
                         
                          <td>{valor.volumen ? valor.volumen.toLocaleString() : 0}</td>
                          <td>{valor.porcentaje} %</td>
                          <td>{valor.acciones ? valor.acciones.toLocaleString(): 0} M</td>
                        </tr>
                        )
                   })
                   :
                    'DE MOMENTO NADA'
                 }
               </tbody>
             
             </Table>
             </Col>
             </Row>
             </Container>
       </>
  );
}
