// import {  useState } from 'react';
import { useContext } from 'react';
import {Table} from 'react-bootstrap';
import {RepoContext} from '../context/ScannerContext';


export const Operacion = () => {

     const {state} = useContext(RepoContext);
    //  const [gestion, setGestion] = useState(state.gestion);
    
      // console.log('Render Taabla estrategia');
      return (
          <>
          <h4>Posible Operacion {state.gestion?.ticker}</h4>
            <Table striped bordered hover>
               <thead>
                 <tr>
                   <th>Posible</th>
                   <th>Acciones a comprar</th>
                   <th>Precio Compra</th>
                   <th>Precio salida</th>
                   <th>Precio STOP</th>
                   <th>Ganancia Simulada</th>
                   <th>Riesgo Simulado</th>
                   <th>Rentabilidad</th>
                   <th>Tamaño Cuenta</th>
                 </tr>
               </thead>
               <tbody>
                 <tr>
                   <td className={
                     state.gestion?.operacion_posible ? 'operacion-posible': 'operacion-imposible'}
                   >{state.gestion?.operacion_posible ? 'SI' : 'NO'}</td>
                   <td>{state.gestion?.acciones_comprar}</td>
                   <td>{state.gestion?.precio_compra.toFixed(2)} €</td>
                   <td>{state.gestion?.precio_salida.toFixed(2)}</td>
                   <td>{state.gestion?.precio_stop.toFixed(2)}</td>
                   <td>{state.gestion?.ganancia_simulada.toFixed(2)} €</td>
                   <td>{state.gestion?.riesgo.toFixed(2)} €</td>
                   <td>{state.gestion?.rentabilidad} %</td>
                   <td>{state.gestion?.tamanyo_cuenta} €</td>
                 </tr>
   
               </tbody>
             </Table>
          </>
      );
}