export const rowsCondicionales = [
    {
      when: (row:any) => row.rompeMaximo === true,
      style: {
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ];

export const columns = [
    // {
    //   name: 'Update',
    //   selector: 'updated',
    //   sortable: true,
    //   center: true,
    //   cell: (row:any) => row.updated.toLocaleString()
    // },
    {
      name: 'Simbolo',
      selector: 'ticker',
      sortable: true,
      center: true,
    },
    {
      name: 'Subida',
      selector: 'porcentaje',
      sortable: true,
      center: true,
      cell: (row:any) => row.porcentaje + ' %',
    },
    {
      name: 'Cotizacion',
      selector: 'cotizacion',
      sortable: true,
      center: true,
    },
    // {
    //   name: 'EMA 9',
    //   selector: 'EMA9',
    //   center: true,
    //   cell: (row:any) => {
    //     try {
    //       // console.log('Row', row.ticker);
    //       return row.EMA9.getResult().toFixed(2)
    //     } catch (error) {
    //       console.log('No hay suficiente data')
    //     }
    //   }
    // },
    // {
    //   name: 'EMA 20',
    //   selector: 'EMA20',
    //   center: true,
    //   cell: (row:any) => {
    //     try {
    //       // console.log('Row', row.ticker);
    //       return row.EMA20.getResult().toFixed(2)
    //     } catch (error) {
    //       console.log('No hay suficiente data')
    //     }
    //   }
    // },
    // {
    //   name: 'ATR-14',
    //   selector: 'ATR14',
    //   sortable: true,
    //   center: true
    // },
    {
      name: 'Maximo',
      selector: 'maximo',
      sortable: true,
      center: true,
      // conditionalCellStyles: [
      //   {
      //     when: (row:any) => row.rompeMaximo === true,
      //     style: {
      //       backgroundColor: 'rgba(63, 195, 128, 0.9)',
      //       color: 'white',
      //       '&:hover': {
      //         cursor: 'pointer',
      //       },
      //     },
      //   },
      //  ]
    },
    {
      name: 'Volumen',
      selector: 'volumen',
      sortable: true,
      center: true,
      cell: (row: any) => row.volumen.toLocaleString()
    },
    {
      name: 'Acciones',
      selector: 'acciones',
      sortable: true,
      center: true,
      cell: (row: any) => row.acciones.toLocaleString() + ' M'
    },
    {
      name: 'Market Cap',
      selector: 'marketCap',
      sortable: true,
      //center: true,
      cell: (row: any) => row.marketCap.toLocaleString()
    },
  
    // {
    //   name: 'RVOL-5 Dias',
    //   selector: 'RVOL',
    //   sortable: true,
    //   center: true,
    //   cell: (row: any) => row.RVOL.toLocaleString() + ' %'
    // },
    // {
    //   name: 'RVOL-5 Minutos',
    //   selector: 'rvol5m',
    //   sortable: true,
    //   center: true,
    //   cell: (row: any) => row.RVOL5.toLocaleString() + ' %'
    // },
  ];