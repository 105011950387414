import { useEffect, useState } from "react";

import { ValoresInterfaceWS } from "../models/valores";


export const useWSMaximos = () => {

    const [maximos, setMaximos] = useState<ValoresInterfaceWS[]>([]);

    useEffect(() => {
        // TODO: Hacer en el backend la ruta MAXIMOS
        let url = `wss://escanner.carlokart.com/maximos`;
        //  let url = 'ws://192.168.1.52:4000';
     
         // const ws = new Websocket(url);
        const ws = new WebSocket(url);
         
        ws.onopen = () => {
           console.log(`Conectado a WS PURO -> /maximos`);
         }
        ws.onmessage = (ev) => {
           // console.log('Data desde server', ev.data);
           let data:ValoresInterfaceWS[] = JSON.parse(ev.data.toString());
           
           if(data.length >= 1){
             // console.log('Leng maximos', maximos.length)
             
             maximos.unshift(...data);
             let nuevos = [...maximos];
             setMaximos(nuevos);
           }
     
         }

        ws.onerror = (ev) => {
             console.log(`Problema al conectar con la url ${url}`);
             console.log(`Error -> ${ev.target}`);
        }
          
        return () => {
            console.log('Desconectamos del SERVER WS Maximos');
            ws.close();
          }
    }, []);
   
    return maximos;
}