export interface GestionInterface{
    ticker:             string;
    acciones_comprar:   number;
    precio_salida:      number;
    rentabilidad:       number;
    tamanyo_cuenta:     number;
    precio_stop:        number;
    comision_operacion: number;
    ganancia_simulada:  number;
    operacion_posible: boolean;
    precio_compra:      number;
    riesgo:             number;
    bp:                 number;


}


export class Entradas implements GestionInterface {
    ticker: string = '';
    tamanyo_cuenta = 0;
    precio_compra = 0;
    riesgo = 0;
    acciones_comprar: number = 0.0;
    precio_salida: number =0.0;
    rentabilidad: number = 5;
    precio_stop: number = 0;
    comision_operacion: number = 7;
    ganancia_simulada: number = 0;
    operacion_posible: boolean = true;
    bp: number = 0;

   
}