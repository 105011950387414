import { useContext } from 'react';

import DataTable from 'react-data-table-component';
import { Acciones, RepoContext } from '../../context/ScannerContext';
import { rowsCondicionales, columns } from './columnas_filas';

import { useWSLista } from '../../hooks/useWSLista';


export const TablaDinamicaWS = (props:any) => {
      // console.log('Props desde Tabla dinamica', props)
      let titulo = ''
      if(props.cap){
    
         titulo = 'Valores ' + props.cap.toUpperCase() + ' CAPS';
      } else {
        titulo = 'Valores: '
      }
       const {dispatch} = useContext(RepoContext);
       
       function handleEstrategia2(row:any, e:any) {
      
          dispatch({type: Acciones.CALCULAR_ESTRATEGIA, payload: row})
            
       }
    
       const valores = useWSLista();
       
      // console.log('Render Tabla Dinamica', maximos.length)
      return(
        <>
          {
           valores ? 
              <DataTable
              title={titulo}
              pointerOnHover={true}
              columns={columns}
              striped={true}
              className='table'
              onRowClicked={handleEstrategia2}
              conditionalRowStyles={rowsCondicionales}
              noDataComponent={
                <div>
                No hay datos
              </div>
              }
              
              data={valores}
            /> : 'No hay data'
          }
       
     
        </>
     );
}

