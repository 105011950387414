import React, {createContext, useReducer} from 'react';
// import { updateValoresPolygon } from '../helpers/valoresPolygon';

import { Scanner, initialState } from '../models/escaner';
import { Entradas, GestionInterface } from '../models/gestion';
// import { ValoresInterface } from '../models/Valores';


export enum Acciones {
    MOSTRAR_VALORES = 'MOSTRAS_VALORES',
    AGREGAR_VALORES = 'AGREGAR_VALORES',
    CALCULAR_ESTRATEGIA = 'CALCULAR_ESTRATEGIA',
    ACTUALIZAR_VALORES = 'ACTUALIZAR_VALORES',
    ACTUALIZAR = 'ACTUALIZAR',
    ROMPE_MAXIMO = 'ROMPE_MAXIMO',
    BUSCAR_Y_AGREGAR = 'BUSCAR_Y_AGREGAR',
    ACTUALIZAR_VALORES_Y_MAXIMOS = 'ACTUALIZAR_VALORES_Y_MAXIMOS',
}


export interface Actions {
    type: Acciones,
    payload: any
}


// export type Action = Acciones;


export type Dispatch = (action: Actions) => void;

// Igual hace falta cambiar el nombre?
// type State = {count: number};


export const RepoContext = createContext<
    {state: Scanner; dispatch: Dispatch}
    >({state: initialState, dispatch: () => undefined});


function scannerReducer(state: Scanner, action: Actions): Scanner {
    switch (action.type){
        case Acciones.MOSTRAR_VALORES: {
            console.log('Dentro de scaner reducer');
        return {
            ...state,
            valores: [],
        }
        }
        case Acciones.AGREGAR_VALORES: {
            return {
                ...state,
                valores: action.payload
            }
        }
        case Acciones.ACTUALIZAR_VALORES: {
            // console.log('Desde reducer actualizamos')
            return {
                ...state,
                valores: action.payload
            }
        }
        case Acciones.ROMPE_MAXIMO: {
             // console.log('Rome maximo desde reducer ')
            return {
                ...state,
                rompenMaximoWS: action.payload
            }
        }
        case Acciones.CALCULAR_ESTRATEGIA: {
            return {
                ...state,
                gestion: calculoOperacion(action.payload)
            }
        }
        
        case Acciones.ACTUALIZAR: {
            return {
                ...state,
                actualizarValores: action.payload,
            }
        }
        case Acciones.BUSCAR_Y_AGREGAR: {
            return {
                ...state,
                valores:action.payload[0],
                buscarNuevosValores: action.payload[1],
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${action}`);
          }
    }
}



function calculoOperacion(row: any): GestionInterface {
    // let tamanyo_cuenta: number = 1000;
    let bp: number = 1000;
    let lotajeMaximo = 200;
    let lotajeMinimo = 100;
    let comision_broker: number = 0;
    let gestion: GestionInterface = new Entradas();
    gestion.ticker = row.ticker;
    gestion.bp = bp;

    gestion.tamanyo_cuenta = bp
    let precio_compra = row.cotizacion;
    gestion.precio_compra = row.cotizacion;
    
    let precio_stop1: number = row.cotizacion - 0.1 ;

    let precio_salida: number = row.cotizacion + 0.3;
    gestion.precio_stop = precio_stop1;
    gestion.precio_salida = precio_salida;
    let acciones_comprar =  Math.round(gestion.bp / row.cotizacion);
    gestion.acciones_comprar = acciones_comprar;
    // gestion.ganancia_simulada = (acciones_comprar * precio_salida) - (precio_compra * acciones_comprar);
    let ganancia_simulada: number = ((acciones_comprar * precio_compra) - (acciones_comprar * precio_stop1)) * 3
    gestion.ganancia_simulada =  ganancia_simulada - comision_broker;
    gestion.riesgo = ((acciones_comprar * precio_compra) - (acciones_comprar * precio_stop1)) + comision_broker;

     if (gestion.acciones_comprar <= lotajeMaximo && gestion.acciones_comprar >= lotajeMinimo){
         gestion.operacion_posible = true;
     } else{
         gestion.operacion_posible = false;
     }
    
    return gestion;
  } 


type ScrennerProps = {children: React.ReactNode}


export const ScrennerProvider = ({children}: ScrennerProps) => {
   
    const [state, dispatch] = useReducer(scannerReducer, initialState);
    return(
        <RepoContext.Provider value={{state, dispatch}}>
            {children}
        </RepoContext.Provider>
    );

}